@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font:
            16px/1.5 'IBM Plex Sans',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #ffffff;
        background-color: rgb(12 18 32);
        font-weight: 400;
        overscroll-behavior-y: none;
    }

    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf) format('truetype');
        font-weight: 300;
        font-display: swap;
    }

    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf) format('truetype');
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf) format('truetype');
        font-weight: 500;
        font-display: swap;
    }

    @font-face {
        font-family: 'IBM Plex Sans';
        src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf) format('truetype');
        font-weight: 700;
        font-display: swap;
    }
}
